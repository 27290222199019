import create from 'zustand';
import axios from 'axios';
import environmnet from '../env.json';

let BACKEND_SERVER = "http://localhost:8800/dev"
if (environmnet.REACT_APP_BACKEND_SERVER && environmnet.NODE_ENV) {
    BACKEND_SERVER = environmnet.REACT_APP_BACKEND_SERVER + "/" + environmnet.NODE_ENV;
}

export const useRefinaryMasterDataStore = create((set) => ({
    partyList: [],
    metals: [],
    uows: [],
    service: {},
    serviceChargeDetails: {},
    getPartyList: async (partyType) => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/master/getpartylist?partyType=${partyType}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json();
                set({ partyList: json_res.data });
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
    getMetalList: async () => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/master/getmetaltypes`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json();
                const urd_metals = json_res.data.filter(obj => obj.metal.includes('URD'));
                set({ metals: urd_metals });
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
    getUowList: async () => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/master/getuomtypes`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json();
                set({ uows: json_res.data });
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
    getService: async (service_id) => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/master/getservice/${service_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json();
                set({ service: json_res.data[0] });
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
    getServiceChargeDetails: async (serviceChargeId, serviceId, partyId) => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/master/getservicechargedetails?serviceChargeId=${serviceChargeId}&serviceId=${serviceId}&partyId=${partyId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json();
                set({ serviceChargeDetails: json_res.data[0] });
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    }
}))

export const useRefinaryDataByPartyStore = create((set) => ({
    aggDataByParty: {},
    aggChartDataByParty: [],
    getAggDataByParty: async (partyId) => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/refinary/getaggregateddatabyparty/${partyId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json()
                set({ aggDataByParty: json_res.data[0] })
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
    getAggChartdataByParty: async (partyId, startMonth, startYear, endMonth, endYear) => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/refinary/getchartdatabymonth/${partyId}?startMonth=${startMonth}&startYear=${startYear}&endMonth=${endMonth}&endYear=${endYear}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json()
                set({ aggChartDataByParty: json_res.data })
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    }
}))

export const useRefinaryTrxnStore = create((set) => ({
    serviceCharge: {},
    refinaryTrxnsData: [],
    getServiceChargeData: async (partyId, serviceId) => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/refinary/getservicecharge/${partyId}?serviceId=${serviceId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json()
                set({ serviceCharge: json_res.data[0] })
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
    getRefinaryTrxnsData: async (partyId, startDate, endDate) => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/refinary/gettransactions/${partyId}?startDate=${startDate}&endDate=${endDate}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json()
                set({ refinaryTrxnsData: json_res.data })
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
    addAddedTransactions: (trxn) => set((state) => {
        if (trxn.constructor.name === 'Object') {
            return {...state, refinaryTrxnsData: [...state.refinaryTrxnsData, trxn]}
        } else if (trxn.constructor.name === 'Array') {
            return {...state, refinaryTrxnsData: [...state.refinaryTrxnsData, ...trxn]}
        }
    }),
    removeDeletedTransaction: (trxn) => set((state) => {
        // let updatedTrxns = state.trxnsData.filter(val => val.trxnId !== trxn.trxnId);
        // return {trxnsData: [...updatedTrxns]}
        let trxns = [...state.refinaryTrxnsData]
        let deleteIndex = trxns.findIndex(val => val.serviceTrxnId === trxn.serviceTrxnId);
        trxns[deleteIndex] = {...trxn, type: 'deleted-trxns'}
        return {refinaryTrxnsData: [...trxns]}
    }),
    updateTransaction: (trxn) => set((state) => {
        let trxns = [...state.refinaryTrxnsData]
        let updateIndex = trxns.findIndex(val => val.serviceTrxnId === trxn.serviceTrxnId);
        trxns[updateIndex] = trxn;
        return {refinaryTrxnsData: [...trxns]}
    }),
    cudTransactions: async (insertEntries, updateEntries, deleteEntries) => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await axios.post(`${BACKEND_SERVER}/api/refinary/save`, { updateEntries, deleteEntries, insertEntries }, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.response && res.response.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }
                return res;

            } else {
                console.log('Token not found');
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                localStorage.removeItem('authToken');
                window.location.href = '/';
            }
            console.error('Error fetching data:', err);
        }
    }
}))
