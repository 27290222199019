import React, { useState, useEffect } from "react";
import './StockLedger.scss';

import MiniTiles from '../../components/minitiles/MiniTiles';
import CustomerInfo from '../../components/info/CustomerInfo';
import DataTableCrudDemo  from '../../components/edit_datatable/EditDatatable';

import { useDataByPartyStore, useTrxnStore, usePartyDataStore } from "../../stores/store";

const StockLedger = () => {
  const partyList = usePartyDataStore((state) => state.partyList);
  const aggDataByParty = useDataByPartyStore((state) => state.aggDataByParty);
  const getAggDataByParty = useDataByPartyStore((state) => state.getAggDataByParty);
  const aggChartDataByParty = useDataByPartyStore((state) => state.aggChartDataByParty);
  const getAggChartdataByParty = useDataByPartyStore((state) => state.getAggChartdataByParty);
  const getTrxnsData = useTrxnStore((state) => state.getTrxnsData);
  
  const tzoffset = (new Date()).getTimezoneOffset() * 60000;

  const sorter2 = (sortBy) => (a, b) => a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1;
  partyList.sort(sorter2('partyName'))

  const dateFormatBodyTemplate = rowData => {
    let date = rowData.trxnDate.toString();
    return `${date.substring(6, 8)}/${date.substring(4, 6)}/${date.substring(0, 4)}`
  }

  const statusBodyTemplate = rowData => {
    return <span className={`product-badge status-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
  };

  const getWeightGramBodyTemplate = rowData => {
    if (rowData.weight) {
      return `${rowData.weight?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
    } else {
      return ''
    }
  };

  const getFinePercentBodyTemplate = rowData => {
    if (rowData.finePercentage) {
      return `${rowData.finePercentage?.toLocaleString('en-IN', {minimumFractionDigits: 2})} %`
    } else {
      return ''
    }
  };

  const getServiceWeightGramBodyTemplate = rowData => {
    if (rowData.serviceWeight) {
      return `${rowData.serviceWeight?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
    } else {
      return ''
    }
  };

  const getDepositsGramBodyTemplate = rowData => {
    if (rowData.deposits) {
      return `${rowData.deposits?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
    } else {
      return ''
    }
  };

  const getWithdrawalsGramBodyTemplate = rowData => {
    if (rowData.withdrawals) {
      return `${rowData.withdrawals?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
    } else {
      return ''
    }
  };

  const getBalanceGramBodyTemplate = rowData => {
    if (rowData.balance) {
      return `${rowData.balance?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
    } else {
      return ''
    }
  };

  const columns = [
    { field: 'trxnId', header: 'Trxn Id', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'trxnDate', header: 'Date', body: dateFormatBodyTemplate, filter: true, hidden: false, sortable: true, style_width: '10rem' },
    { field: 'metal', header: 'Metal', filter: true, hidden: true, sortable: true, style_width: '8rem' },
    { field: 'mode', header: 'Mode', filter: true, hidden: false, sortable: true, style_width: '8rem' },
    { field: 'remarks', header: 'Remarks/Particulars', filter: true, hidden: false, sortable: true, style_width: '12rem' },
    { field: 'weight', header: 'Weight', body: getWeightGramBodyTemplate, filter: true, hidden: true, sortable: true, headerStyle: 'left', style_width: '12rem', align: 'right' },
    { field: 'finePercentage', header: 'Fine Percent', body: getFinePercentBodyTemplate, filter: true, hidden: true, sortable: true, headerStyle: 'left', style_width: '12rem', align: 'right' },
    { field: 'serviceWeight', header: 'Service Weight', body: getServiceWeightGramBodyTemplate, filter: true, hidden: true, sortable: true, headerStyle: 'left', style_width: '12rem', align: 'right' },
    { field: 'deposits', header: 'Deposits', body: getDepositsGramBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '12rem', align: 'right' },
    { field: 'withdrawals', header: 'Withdrawals', body: getWithdrawalsGramBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '12rem', align: 'right' },
    { field: 'balance', header: 'Balance', body: getBalanceGramBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '12rem', align: 'right' },
    { field: 'status', header: 'Status', body: statusBodyTemplate, filter: true, hidden: false, sortable: true, style_width: '12rem' },
    { field: 'roProcessId', header: 'RO Process Id', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'dateCreated', header: 'Date Created', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'createdBy', header: 'Created By', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'dateModified', header: 'Date Modified', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'modifiedBy', header: 'Modified By', filter: true, hidden: true, sortable: true, style_width: '12rem' }
  ];

  const [dateRange, setDateRange] = useState([new Date((new Date()).getFullYear(), (new Date()).getMonth()-12, 1), new Date()]);
  const [selectedValue, setSelectedValue] = useState(partyList[0].partyId);
  const [partyName, setPartyName] = useState(partyList[0].partyName);
  const [partyType, setPartyType] = useState(partyList[0].partyType);
  const [partyEmail, setPartyEmail] = useState(partyList[0].email);
  const [partyPhone, setPartyPhone] = useState(partyList[0].phone);
  const [partyAddress, setPartyAddress] = useState(partyList[0].address);

  getTrxnsData(selectedValue, 
      (new Date(dateRange[0] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')), 
      (new Date(dateRange[1] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')));
  
  useEffect(() => {
      
      let d = new Date();
      let endMonth = d.getMonth()+1;
      let endYear = d.getFullYear();
      let startMonth = endMonth-1;
      let startYear = endYear-1;

      getAggChartdataByParty(selectedValue, startMonth, startYear, endMonth, endYear);    
      getAggDataByParty(selectedValue);
      
      const newPartyList = partyList.filter((el) => {
        return el.partyId == selectedValue
      });
      setPartyName(newPartyList[0].partyName);
      setPartyType(newPartyList[0].partyType);
      setPartyEmail(newPartyList[0].email);
      setPartyPhone(newPartyList[0].phone);
      setPartyAddress(newPartyList[0].address);
    }, [selectedValue]);
           
    return (
      <div className='stockledgerMain'>
        <div className='stockledgerTop'>
          <div className="singleContainer">
            <div className="top">
              <div className="left">
                  <label>Customer: </label>
                  <select className="dropdownInputStyle" onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                    {partyList.map((option, key) => {
                        return <option key={key} value={option.partyId} >{option.partyName}</option>;
                    })}
                  </select>
              </div>
              <div className="right">
                <div className="mini-tiles">
                  <MiniTiles type="deposit" total={aggDataByParty?.deposits}/>
                  <MiniTiles type="withdrawl" total={aggDataByParty?.withdrawals}/>
                  <MiniTiles type="balance" total={aggDataByParty?.balance}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <CustomerInfo partyId={selectedValue} name={partyName} type={partyType} email={partyEmail} phone={partyPhone} address={partyAddress} />
        </div>

        <div>
          <DataTableCrudDemo 
            cols={columns} 
            partyId={selectedValue} 
            partyName={partyName} 
            type={partyType}
            phone={partyPhone}
            address={partyAddress}
          />
        </div>
    </div>
  )
}

export default StockLedger